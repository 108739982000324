/* jshint esnext: true */
import AbstractModule from './AbstractModule'

export default class extends AbstractModule {
	constructor(options) {
		super(options);
		this.$inputs = this.$el.find(':input');
		this.inputSelectors = 'input[type=text], input[type=password], input[type=email], input[type=url], input[type=tel], input[type=number], input[type=search], textarea';
		this.$panes = this.$el.find('.js-form-pane');
		this.$consent = this.$el.find('.js-form-consent');
		this.$consentLabel = this.$el.find('.js-form-consent-label');
		this.$consentError = this.$el.find('.js-form-consent-error');
		this.$limitError = this.$el.find('.js-form-date-limit-error');
		this.$formatError = this.$el.find('.js-form-date-format-error');
		this.$criteriaError = this.$el.find('.js-form-required-criteria-error');
		this.$formWrap = this.$el.find('.js-form-wrap');
		this.$textCountWords = this.$el.find('.js-form-count-words');
		this.$formFeedback = this.$el.find('.js-form-feedback');
		this.currentPane = 0;
		this.criteriaHasError = false;

		this.updateTextFields();

		var _this = this;
        var $captchaContainer = $('.g-recaptcha');

        if ($captchaContainer.children().length === 0){
            var url = $captchaContainer.prev().attr('src');
            $.getScript( url );
        }

        if (_this.$textCountWords.length > 0){
            _this.$textCountWords.each(function(i, el){
                var $inputElement = $(el);
                var limit = $inputElement.data('maxwords');
                var type = $inputElement.data('maxtype') || "words";
                $inputElement.simplyCountable({
                    counter : '#count-' + $inputElement.attr('name'),
                    maxCount : limit,
                    countType : type,
                    strictMax : true,
                    over : 'has-error',
                });
            });
        }

		// Switch form panes
		// Simple switch between index 0 and 1 for now
		this.$el.on('click.ContactForm', '.js-switch-pane', () => {
			this.goToNextPane();
		});
		// Switch form panes according options
		this.$el.on('click.ContactForm', '.js-switch-pane-opts', function () {
            var $inputElement = $(this);
            var $options = $inputElement.data('options');

            if (!$inputElement.is('input')){
                $inputElement.children('input').prop('checked', true);
            }

            if (typeof $options !== 'object'){
                $options = jQuery.parseJSON($options);
            }
			_this.goToPane($options);
		});

		this.$el.on('click.ContactForm', '.js-submit', () => {
			this.$el.submit();
		});

		this.$el.on('submit.ContactForm', function(event) {
			event.preventDefault();

			if (_this.currentPane === 0) {
				_this.goToNextPane();
			} else {
                 $('.g-recaptcha-response').each(function(){
                    var $inputElement = $(this);
                    if ($inputElement.val().length > 0){
                        $('#captcha-response').val($inputElement.val());
                        return false;
                    }
                });
				var data = new FormData(this);
				_this.submitForm(event, data);
			}
		});

		// Add active if form auto complete
		this.$el.on('change.ContactForm', this.inputSelectors, function () {
			var $inputElement = $(this);

			if($inputElement.val().length !== 0 || $inputElement.attr('placeholder') !== undefined) {
				$inputElement.siblings('label').addClass('is-active');
			}
			var error = _this.fieldHasError($inputElement);
		});

		// Add active when element has focus
		this.$el.on('focus.ContactForm', this.inputSelectors, function () {
            var $inputElement = $(this);
			$inputElement.siblings('label, i').addClass('is-active');

		});

		this.$el.on('blur.ContactForm', this.inputSelectors, function () {
			var $inputElement = $(this);

			if ($inputElement.val().length === 0 && $inputElement[0].validity.badInput !== true && $inputElement.attr('placeholder') === undefined) {
				$inputElement.siblings('label, i').removeClass('is-active');
			}

			if ($inputElement.val().length === 0 && $inputElement[0].validity.badInput !== true && $inputElement.attr('placeholder') !== undefined) {
				$inputElement.siblings('i').removeClass('is-active');
			}

			var error = _this.fieldHasError($inputElement);
		});
	}

	/**
	 * Upon form submit, many things happen
	 * - Validation of inputs
	 * - Validation of consent
	 * - Delivery of form data to the server
	 * - Managing server response states
	 *
	 * @param  {Event}         event  jQuery Event object
	 * @param  {array|string}  data  Form data
	 */
	submitForm(event, data) {
		var hasErrors = false;
        var current   = this.$panes.eq(this.currentPane);

		var hasErrors = this.fieldsHaveErrors(current.find(':input'));

		if (this.$consent.length > 0 && !this.$consent.is(':checked')) {
			this.$consentLabel.addClass('has-error');
			this.$consentError.removeClass('is-hidden');
			hasErrors = true;
		}

		if (!hasErrors) {
			this.$consentLabel.removeClass('has-error');
			this.$consentError.addClass('is-hidden');

			var jqxhr = $.ajax({
				method: 'POST',
				url: this.$el.attr('action'),
				data: data,
                contentType: false,
                cache: false,
                processData:false,
			})
			.done((response) => {
				if (response.success === true) {
					this.$panes.eq(this.currentPane).fadeOut(() => {
						this.$formFeedback.fadeIn();
					});
				} else {
					this.manageErrors(response.errors);
				}
			})
			.fail((response) => {
				console.log('error', response);
			});
		}
	}

	/**
	 * Simple function to determine if form is valid enough to switch panes
	 * Used by 'Next' button and form submit
	 */
	goToNextPane() {
        var current = this.$panes.eq(this.currentPane);
		if (!this.fieldsHaveErrors(current.find(':input'))) {
			current.fadeOut(() => {
				this.currentPane = 1;
				this.$panes.eq(this.currentPane).fadeIn();
			});
		}
	}

	/**
	 * Function to determine if form is valid enough to switch panes
	 * Used by 'Next' button and form submit
     * @param {object} $options Parameters to defined actions.
	 */
	goToPane($options) {
        var $validate = true;
        var $isValid = false;
        var $currentPane = this.currentPane;
        var $current = this.$panes.eq($currentPane);
//        if ($options.action !== undefined){
//            this.$el.attr('action', $($options.action).val());
//        }
        if ($options.goto !== undefined){
            var $index = $options.goto;

            if (typeof $index === 'string'){
                var $target = this.$panes.filter($index);
                this.currentPane = this.$panes.index($target);
            }else{
                this.currentPane = parseInt($options.goto);
            }

            if ($currentPane == 8 && this.currentPane == 10){
                delete $options.validate;
                var $tmp = $('#organization_registration');
                var txt = $tmp.next().text();
                $tmp.removeClass('js-validate');
                $tmp.next().text(txt.replace(/\*/, ''));
            }
            
            if ( $options.validate  !== undefined){
                $validate = $options.validate;
            }
        }

        if ($validate){
            $isValid = !this.fieldsHaveErrors($current.find(':input'));
        }

		if ($isValid || !$validate) {
			$current.fadeOut(() => {
                var nextPane = this.$panes.eq(this.currentPane);
                var nextOptions = null;
                var prevOptions = null;

                if ($options.prev !== undefined){
                    prevOptions = $options.prev;
                    prevOptions = '{"goto":"'+$options.prev+'", "validate" : 0}';
                    nextPane.find('.o-form_button > .js-prev-btn').data('options', prevOptions);
                }
                if ($options.next !== undefined){
                    nextOptions = '{"goto":"'+$options.next+'"}';
                    nextPane.find('.o-form_button > .js-next-btn').data('options', nextOptions);
                }
                nextPane.fadeIn();
			});
		}else{
            this.currentPane = $currentPane;
        }
	}

	/**
	 * Loop through errors sent back by the servers and attempt to identify the faulty elements
	 * @param  {array}  errors  An array of element names that are in error
	 */
	manageErrors(errors) {
		var i = 0, len = errors.length;
		for (; i < len; i++) {
            if (errors[i] === 'g-recaptcha'){
                $('.g-recaptcha-error').removeClass('u-hidden');
            }else{
                $('#' + errors[i]).addClass('has-error');
            }
		}
	}

	/**
	 * Function to update labels of text fields
	 * @see  MaterializeCSS
	 */
	updateTextFields() {
		$(this.inputSelectors).each(function(index, element) {
			if ($(element).val().length > 0 || element.autofocus || $(this).attr('placeholder') !== undefined || $(element)[0].validity.badInput === true) {
				$(this).siblings('label, i').addClass('is-active');
			} else {
				$(this).siblings('label, i').removeClass('is-active');
			}
		});
	}

	/**
	 * Function to validate single inputs
	 * @see  MaterializeCSS
	 */
	fieldHasError($object) {
		var hasLength = $object.attr('length') !== undefined;
		var lenAttr = parseInt($object.attr('length'));
		var len = $object.val().length;
		var fieldHasError = false;

		if ($object.hasClass('js-validate')) {
			if ($object.val().length !== 0) {
				if ($object[0].validity.badInput !== false) {
					// Check for character counter attributes
					if (($object.is(':valid') && hasLength && (len <= lenAttr)) || ($object.is(':valid') && !hasLength)) {
					} else {
						fieldHasError = true;
					}
				}
				if ($object.attr('type') == 'email') {
					var EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
					if (!EMAIL_REGEX.test($object.val())) {
						fieldHasError = true;
					}
				}
                if ($object.data('validate') === 'date') {
                    if (!isNaN(Date.parse($object.val()))){
                        var $limit = parseInt($object.data('limit')) | 0;
                        var $date = new Date($object.val());
                        var $dateLimit = new Date();
                        $dateLimit.setMonth($dateLimit.getMonth() + $limit);

                        if ($date < $dateLimit){
                            fieldHasError = true;
                            this.$limitError.removeClass('u-hidden');
                            this.$formatError.addClass('u-hidden');
                        }
                    }else{
                        fieldHasError = true;
                        this.$formatError.removeClass('u-hidden');
                    }
				}

                if ($object.data('validate') === 'criteria'
                    && $object.is(':checked')){
                    if ($object.val() === 'no'){
                        fieldHasError = true;
                        this.$criteriaError.removeClass('u-hidden');
                        this.criteriaHasError = true;
                    }else{
                        this.criteriaHasError = false;
                    }
                }else if ($object.attr('type') === 'radio'
                    && $object.data('validate') !== 'criteria'){
                    var radioElem = $('input[name="' + $object.attr('name') + '"]');
                    radioElem.each(function(i, e){
                        fieldHasError = true;
                        if ($(e).is(':checked')){
                            fieldHasError = false;
                            return false;
                        }
                    });
                }
			} else {
				fieldHasError = true;
			}

			if (fieldHasError) {
				$object.addClass('has-error');
                if ($object.attr('type') == 'radio') {
                    $object.parents('.o-form_item:first').children('label:first').addClass('has-error');
                }
			} else {
				$object.removeClass('has-error');
                this.$limitError.addClass('u-hidden');
                this.$formatError.addClass('u-hidden');
                this.$criteriaError.addClass('u-hidden');
                if ($object.attr('type') == 'radio') {
                    $object.parents('.o-form_item:first').children('label:first').removeClass('has-error');
                }

			}
		}

		return fieldHasError;
	}

	/**
	 * Validate multiple inputs
	 * @return {boolean}
	 */
	fieldsHaveErrors($inputs) {
		var i = 0;
		var len = $inputs.length;
		var hasErrors = false;

		for (;i < len; i++) {
			if(this.fieldHasError($inputs.eq(i))) {
				hasErrors = true;
                if (this.criteriaHasError){
                    break;
                }
			}
		}

		return hasErrors;
	}

	destroy() {
		this.$el.off('.ContactForm');
	}
}
